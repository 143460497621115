.hole-museum{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.undermenues{
    width: 100vw;
    display: flex;
}

.menu-title{
    width: calc(100vw/3);
    text-align: center;
    color: #2c507b;
    font-size: 20px;
    font-weight: bolder;
    border-bottom: 1px solid gray ;
    border-right: 1px solid gray;
    cursor: pointer;
}

.menu-title:last-child{
    border-right: none;
}

.menu-title p{
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: transform ease-in 0.2s;
}

.menu-title p:hover{
    transform: scale(1.1);
    transition: transform ease-in 0.3s;
}

.choosed{
    background-color: #2c507b;
    color: white;
}

.parent-div{
    position: relative;
}

.image-slide-main{
    width: 100vw;
    height: calc(100vh - 130px);
    overflow-x: auto;
    overflow-y: hidden;
}

.image-slide-main::-webkit-scrollbar{
    display: none;
}

.image-div{
    width: fit-content;
    height: calc(100vh - 130px);
    display: flex;
    align-items: center;
}

.image-div img{
    width: 100vw;
}

.image-div img:first-child{
    animation: sliding 1s;
}

@keyframes sliding{
    from {
        opacity: 0;
        transform: rotate(-30deg)
    }
    to {
        opacity: 1;
        transform: rotate(0deg);
    }
}

.control-btn{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4vw;
    height: 4vw;
    background-color: rgba(105, 101, 101, 0.527);
    border-radius: 50%;
    top: calc((100vh - 130px - 2vw)/2);
    cursor: pointer;
    transform: scale(1);
    transition: transform ease-in 0.3s;
    color: rgb(82, 80, 80);
}

.control-btn:hover{
    transform: scale(1.05);
    transition: transform ease-in-out 0.3s;
}

.left{
    left: 2vw;
}

.right{
    right: 2vw;
}

.control-icon{
    font-size: 2vw;
}

.text{
    position: absolute;
    top: 5vh;
    right: 3vw;
    color: white;
    font-size: 48px;
    font-weight: bold;
    width: 0;
    overflow: hidden;
    transition: width ease-in 1s;
}

.show{
    width: 310px;
    transition: width ease-in 1s;
}

.history-div{
    width: 80vw;
    margin: 20px 0;
}

.booking-div{
    margin: 25px 0;
    padding: 15px;
}

.date{
    display: flex;
    justify-content: space-around ;
    width: 60vw;
}

.date p{
    color: #2c507b;
    font-size: 14px;
    text-wrap: nowrap;
    width: 20vw;
}

.name-input{
        background: transparent;
        outline: none;
        border: none;
        margin: 10px 0;
        text-align: center;
        border-bottom: 1px solid #2c507b;
        border-radius: 10px;
        color: #2c507b;
        width: 50vw;
    }

.name-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.book-btn{
    background-color: #2c507b;
    color: white;
    margin: 20px 0;
    border: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;
    padding: 15px;
    width: 100%;
    cursor: pointer;
}

.contact-div{
    display: flex;
    justify-content: space-between;
    width: 60vw;
}

.contact-part-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
}

.contact-part-icon{
    font-size: 30px;
    color: #2c507b;
    margin-top: 20px;
}

.contact-part-title{
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
}

.contact-part-div a{
    padding: 16px 0;
    color: black;
}
