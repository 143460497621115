.header-div{
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    background-color: rgba(196, 196, 190, 0.2);
    box-shadow: 0px 5px 5px rgba(168, 167, 167, 0.692)
}

.home-header-div{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    backdrop-filter: sepia(30%)
}

.logo-div{
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 50%;
}

.logo-image{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-left: 15px;
}

.name-div{
    display: block;
    text-align: center;
    align-self: center;
}

.name-h{
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-family: Serif;
    /* color: #2c507b; */
}

.search-lang-end{
    display: flex;
    align-items: end;
}

.search-lang-div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

@media (max-width: 600px){
    .header-div{
        height: 80px;
    }

    .logo-image{
        min-width: 50px;
        max-width: 50px;
        height: 50px;
    }

    .name-div{
        display: none;
    }
}
