.home-div{
    position: relative;
    display: flex;
    width: 100vw;
    height: 500px;
    overflow: hidden;
    margin-bottom: 40px;
}

.gradient{
    position: absolute;
    top:50px;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 250px;
    background: linear-gradient(transparent, #f3f3f2 100%)
}

.animation{
    width: 100vw;
    height: 500px;
}

.on-animation{
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 5;
}

.menu-on-animation{
    display: flex;
    width: fit-content;
    justify-content: end;
    margin-top: 2px;
    margin-right: 20px ;
}

.search-lang{
    /* position: absolute; */
    display: flex;
    margin-top: 14px;
    /* z-index: 10; */
    /* bottom: 20px; */
    /* right: 20px; */
}

.name-h{
    display: flex;
    width: fit-content;
    color: white;
    margin: 14px 0 20px 30px ;
}

.slogan{
    position: absolute;
    top:0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
    text-align: center;
    font-size: 40px;
    color: white;
    font-weight: 700;
}

.name-h div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.name-h img{
    width: 40px;
    margin-right: 10px;
}

.name-h h1{
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    width: fit-content;
    text-align: start;
}

.home-info-div-scroll{
    width: 100vw;
}


@keyframes infoslide{
    0%{
        transform: translateX(0);
    }
    48%{
        transform: translateX(0);
    }
    50%{
        transform: translateX(-100vw)
    }
    98%{
        transform: translateX(-100vw);
    }
    100%{
        transform: translateX(-200vw)
    }


}

.home-info-div{
    display: flex;
    width: fit-content;
    animation: infoslide ease-in-out 10s infinite;
}

.home-info{
    display: flex;
    width: 90vw;
    margin: 0 5vw;
    margin-bottom: 25px;
    border-radius: 20px;
}

.bgblue{
    background-color: #2c507b;
}

.bggray{
    background-color: gray;
}

.home-info-image{
    width: 50%;
    height: calc(45vw / 1.55);
    overflow: hidden;
}

.home-info img{
    display: block;
    width: 45vw;
}

.home-info-image-from-right{
    border-radius: 0 20px 20px 0;
}

.home-info-text{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 50%;
    color: white;
    font-size: 20px;
    font-weight: 500;

}

.home-info-text div{
    margin: 40px;
}

.home-info-image-from-left{
    border-radius: 20px 0 0 20px;
}